<template>
    <v-card height="300" width="400" flat class="shadow d-flex flex-column">
        <v-img
            class="align-start card-image"
            :src="item.image_url"
            height="200"
            cover
        >
            <v-card-title class="pa-0 justify-end">
                <dropdown-menu
                    :is-text="true"
                    :can-close="true"
                    icon="fas fa-ellipsis-v"
                >
                    <template #listItems>
                        <v-list>
                            <v-list-item
                                class="item-hover px-0"
                                @click="$emit('edit-item', item)"
                            >
                                <v-btn text color="accent darken-4">
                                    <v-icon size="12" class="mr-2">
                                        fa-solid fa-pen
                                    </v-icon>
                                    <span class="table-menu-item"
                                        >{{
                                            $t(
                                                `Admin.dataTable.tableActions.edit`
                                            )
                                        }}
                                    </span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item
                                class="item-hover px-0"
                                @click="$emit('delete-item', item)"
                            >
                                <v-btn text color="error lighten-1">
                                    <v-icon size="12" class="mr-2">
                                        fa-solid fa-trash
                                    </v-icon>
                                    <span class="table-menu-item">{{
                                        $t(
                                            `Admin.dataTable.tableActions.delete`
                                        )
                                    }}</span>
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </template>
                </dropdown-menu>
            </v-card-title>
        </v-img>
        <v-card-text class="fill-height flex-col-between pb-1">
            <p class="ma-0">{{ item.title }}</p>
            <div class="d-flex justify-end align-items-center">
                <!-- <small class="text-muted">20.06.2022</small> -->
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import DropdownMenu from "./components/DropdownMenu.vue";

export default {
    name: "EventsCard",
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    components: { DropdownMenu },
    data() {
        return {};
    },
};
</script>
