<template>
    <v-container class="my-10">
        <v-row align="center">
            <v-col class="d-flex align-center">
                <v-icon normal left color="purple">fa fa-rss</v-icon>
                <h2 class="main-heading primary--text">
                    {{ $t(`Admin.new.mainHeading`) }}
                </h2>
            </v-col>
            <v-col class="text-right">
                <v-btn
                    depressed
                    dense
                    outlined
                    link
                    :to="{
                        name: 'CreateEditNews',
                        query: { from: 'news' },
                    }"
                    class="pa-7"
                    color="primary"
                >
                    <v-icon size="14" class="mr-2"> fa-solid fa-plus </v-icon>
                    {{ $t(`Admin.new.createNew`) }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="news__container" justify="center">
            <v-col cols="12">
                <v-row v-if="getNewsStatus_Success">
                    <v-col
                        v-for="(item, idx) in items"
                        :key="idx"
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <events-card
                            :item="item"
                            @edit-item="editItem($event)"
                            @delete-item="deleteItem($event)"
                        ></events-card>
                    </v-col>
                </v-row>
                <v-row v-else-if="getNewsStatus_Pending" class="fill-height">
                    <v-col>
                        <loading-spinner></loading-spinner>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <div v-if="pageCount > 1" cols="12">
            <v-divider class="mb-4"></v-divider>

            <div class="text-center table-pagination">
                <v-pagination
                    v-model="page"
                    class="table-pagination"
                    :length="pageCount"
                    :total-visible="7"
                    elevation="0"
                ></v-pagination>
            </div>
        </div>
        <confirm-modal :open="openModal" :close.sync="openModal">
            <template #content>
                <span class="modal-content">
                    {{ $t(`Admin.new.confirmTxt`) }}
                </span>
            </template>
            <template #action>
                <v-btn
                    depressed
                    dense
                    color="error lighten1"
                    class="mr-2"
                    :loading="deleteStatus_Pending"
                    @click="confirmDelete()"
                >
                    {{ $t(`Admin.modal.confirm`) }}
                </v-btn>
            </template>
        </confirm-modal>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import { getAllNews, deleteNew } from "@/api/adminApi.js";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import EventsCard from "./NewsEventsCard.vue";

export default {
    name: "News",
    components: {
        ConfirmModal: () => import("@/components/ConfirmModal.vue"),
        LoadingSpinner,
        EventsCard,
    },

    data() {
        return {
            items: [],
            page: 1,
            pageCount: 0,

            getNewsStatus: apiStatus.Idle,
            openModal: false,

            itemToDelete: null,
            deleteStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getNewsStatus", "deleteStatus"]),
    },

    methods: {
        async getNews() {
            this.getNewsStatus = apiStatus.Pending;

            const payload = {
                page: this.page,
                items: 6,
            };

            const { response, error } = await withAsync(getAllNews, payload);

            if (error) {
                this.getNewsStatus = apiStatus.Error;
                return;
            }

            this.items = response.data.data;
            this.pageCount = response.data.last_page;
            this.getNewsStatus = apiStatus.Success;
            console.log('fetched news here', this.items)
        },
        redirectToNewsCrud() {
            this.$router.push({ name: "CreateEditNews" });
        },

        editItem(item) {
            this.$router.push({
                name: "CreateEditNews",
                params: {
                    id: item.id,
                },
                query: {
                    from: "news"
                }
            });
        },

        deleteItem(item) {
            this.itemToDelete = item;
            this.openModal = true;
        },

        async confirmDelete() {
            this.deleteStatus = apiStatus.Pending;

            const payload = {
                id: this.itemToDelete.id,
            };

            const { response, error } = await withAsync(deleteNew, payload);

            if (error) {
                this.deleteStatus = apiStatus.Error;
                return;
            }

            this.deleteStatus = apiStatus.Success;
            this.getNews();
            this.openModal = false;
        },
    },

    watch: {
        openModal(newVal) {
            if (newVal === false) {
                this.itemToDelete = null;
            }
        },

        page(newVal) {
            this.getNews();
        },
    },
    created() {
        this.getNews();
    },
};
</script>

<style scoped>
.shadow {
    box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important;
}
.card-image {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.news__container {
    min-height: 650px;
    margin-bottom: 32px;
}
</style>
